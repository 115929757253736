.App {
  text-align: center;
  overflow-x: hidden;
}

html {
	scroll-behavior: smooth;
}

.flex, .lazyload-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-height: 100%;
}

.item-appear {
  opacity: 0;
  transform: scale(0.9);
  transition: width 2s, height 4s;
}

.item-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 3s;
  transition: opacity 1000ms, transform 1000ms, width 2s, height 2s;
}

.left {
  justify-content: flex-start;
  align-content: flex-start;
  text-align: start;
}

.right {
  justify-content: flex-end;
  align-content: flex-end;
  text-align: end;
}

.column {
  flex-direction: column;
}

.space-evenly {
  justify-content: space-evenly;
}

.row {
  flex-direction: row;
}

.fill {
  flex: 1 1
}

.fit {
  flex: 0 1
}

.center-item, .center-item > .lazyload-wrapper {
  justify-content: center;
  align-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}